import React from "react";
import "./Frame11342.css";

function Frame11342() {
  return (
    <div className="frame-113-8">
      <div className="cur-performance valign-text-middle inter-medium-star-dust-18px">Cur. Performance</div>
    </div>
  );
}

export default Frame11342;
