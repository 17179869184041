import React from "react";
import "./Frame1164.css";

function Frame1164() {
  return (
    <div className="frame-116-163">
      <div className="smed valign-text-middle inter-medium-star-dust-18px">SMED</div>
    </div>
  );
}

export default Frame1164;
