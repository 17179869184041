import React from "react";
import "./Frame4.css";

function Frame4() {
  return (
    <div className="frame-4-1">
      <div className="processing valign-text-middle inter-bold-mineral-green-18px">Processing</div>
    </div>
  );
}

export default Frame4;
