import React from "react";
import "./Frame1146.css";

function Frame1146() {
  return (
    <div className="frame-114-96">
      <div className="x5-s valign-text-middle inter-medium-star-dust-18px">5S</div>
    </div>
  );
}

export default Frame1146;
