import React from "react";
import "./Frame11452.css";

function Frame11452() {
  return (
    <div className="frame-114-82">
      <div className="functional-view valign-text-middle inter-medium-star-dust-18px">Functional view</div>
    </div>
  );
}

export default Frame11452;
