import React from "react";
import "./Frame522.css";

function Frame522() {
  return (
    <div className="frame-5-3">
      <div className="manufacturing valign-text-middle inter-bold-mineral-green-18px">Manufacturing</div>
    </div>
  );
}

export default Frame522;
